import { extendTheme } from '@chakra-ui/react'
import colors from './foundations/colors'
import Button from './components/button'
import Input from './components/input'

const fonts = {
  body: 'splunk_data_sans, Arial, Helvetica, sans-serif',
}

const theme = extendTheme({
  fonts,
  colors,
  components: {
    Button,
    Input,
  },
})

export default theme
