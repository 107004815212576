import { createRoot } from 'react-dom/client'
import { StrictMode } from 'react'
import { ChakraProvider } from '@chakra-ui/react'
import theme from 'themes/theme'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import App from './App'

import './index.css'

const container = document.getElementById('root')

if (container) {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        cacheTime: 0,
        retry: false,
        refetchInterval: false,
        refetchOnMount: false,
        refetchOnReconnect: false,
        refetchOnWindowFocus: false,
      },
    },
  })

  const root = createRoot(container)
  root.render(
    <StrictMode>
      <QueryClientProvider client={queryClient}>
        <ChakraProvider theme={theme}>
          <App />
        </ChakraProvider>
      </QueryClientProvider>
    </StrictMode>
  )
}
