export default {
  brand: {
    50: '#ED0080',
    100: '#E8339B',
    150: '#E20082',
    200: '#F6F8F9',
    border: {
      50: '#0070F3',
      100: '#868B92',
      150: '#969DAA',
    },
    text: {
      50: '#6B6B6B',
      100: '#F0F3F7',
      150: '#363C44',
      200: '#656C76',
      250: '#0C1724',
    },
  },
}
