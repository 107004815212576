import { lazy } from 'react'
import { Navigate } from 'react-router-dom'
import { Authentication, Layout } from 'common/components'
import { APP_ROUTES } from './config/routes'

const RegisterPage = lazy(() => import('pages/register/RegisterPage'))
const ThankYouPage = lazy(() => import('pages/thank-you/ThankYouPage'))
const ErrorPage = lazy(() => import('pages/error/ErrorPage'))

const routes = [
  {
    path: APP_ROUTES.root,
    element: <Navigate to={APP_ROUTES.register} replace />,
  },
  {
    path: APP_ROUTES.register,
    element: <RegisterPage />,
  },
  {
    path: APP_ROUTES.thankYou,
    element: (
      <Authentication>
        <Layout>
          <ThankYouPage />
        </Layout>
      </Authentication>
    ),
  },
  {
    path: APP_ROUTES.error,
    element: (
      <Layout>
        <ErrorPage />
      </Layout>
    ),
  },
]

export default routes
