export const APP_ROUTES = {
  error: '/error',
  root: '/',
  register: '/register',
  thankYou: '/thank-you',
}

export const API_ROUTES = {
  login: '/session/login',
  register: '/register',
  stacks: '/stacks',
  submit: '/submit',
  temporaryToken: '/session/temporarytoken',
}

export default { APP_ROUTES, API_ROUTES }
