import { Box, Container, Flex, Grid, GridItem, Image, Link, Text } from '@chakra-ui/react'
import { ChevronRightIcon } from '@chakra-ui/icons'

import bgErrorImage from 'assets/images/error-bg.svg'
import oopsImage from 'assets/images/oops.svg'

function ErrorPage() {
  return (
    <Box w="100vw" bgImage={`url(${bgErrorImage})`} bgSize="cover">
      <Container maxWidth="8xl" padding="107px 73px 107px 170px">
        <Grid templateColumns="repeat(2, auto)" gap="23px">
          <GridItem>
            <Image src={oopsImage} alt="Oops!" />
          </GridItem>
          <GridItem>
            <Flex direction="column" align="center">
              <Text as="span" fontSize="40px" fontWeight="light" lineHeight="1.4" color="brand.text.250" mb={8}>
                Service Unavailable
              </Text>

              <Text as="span" fontSize="22px" lineHeight="1.4" color="brand.text.200" textAlign="center">
                We&rsquo;re sorry, but we were unable to process your request.
              </Text>

              <Text as="span" fontSize="xl" lineHeight="1.4" color="brand.text.250" mb="48px" textAlign="center">
                Please try one of these options to get back on track:
              </Text>

              <Flex alignSelf="flex-start">
                <ChevronRightIcon viewBox="0 0 20 20" mr={1} mt="2px" />
                <Text as="div" fontSize="18px" lineHeight="1.4" color="brand.text.150" mb={4}>
                  If your contract was not processed, please go back and click on&nbsp;
                  <Text as="span" fontWeight="bold">
                    Create Contract&nbsp;
                  </Text>
                  again.
                </Text>
              </Flex>

              <Flex alignSelf="flex-start">
                <ChevronRightIcon viewBox="0 0 20 20" mr={1} mt="2px" />
                <Text as="div" fontSize="18px" lineHeight="1.4" color="brand.text.150">
                  You may just need to sign in again by clicking on the link&nbsp;
                  <Text as="span" fontWeight="bold">
                    Click here to setup your account.
                  </Text>
                </Text>
              </Flex>

              <Text
                as="span"
                fontSize="18px"
                lineHeight="1.4"
                color="brand.text.150"
                mt="40px"
                textAlign="center"
                maxWidth="480px"
              >
                If this doesn&rsquo;t help and you continue to get this message, please contact&nbsp;
                <Link href="https://aws.amazon.com/contact-us/" color="brand.border.50" isExternal>
                  AWS Support.
                </Link>
              </Text>
            </Flex>
          </GridItem>
        </Grid>
      </Container>
    </Box>
  )
}

export default ErrorPage
