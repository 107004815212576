import type { ComponentStyleConfig } from '@chakra-ui/theme'

const Input: ComponentStyleConfig = {
  variants: {
    outline: {
      field: {
        h: '50px',
        borderRadius: 'sm',
        border: '1px',
        borderColor: 'brand.border.150',
        _focus: {
          boxShadow: 'rgba(0, 164, 253, 0.6) 0px 0px 1px 2px',
        },
      },
    },
  },
}

export default Input
