import axios, { AxiosRequestConfig } from 'axios'
import { AuthContext } from 'common/components'
import { useContext } from 'react'

const http = axios.create({ baseURL: '/api/v1' })

export default function useHttp() {
  const { authState } = useContext(AuthContext)

  http.interceptors.request.use(
    async (config: AxiosRequestConfig) => {
      const newConfig = config
      const token = authState?.currentUser?.token

      if (token) {
        newConfig.headers = {
          ...config.headers,
          Authorization: `Bearer ${token}`,
        }
      }

      return newConfig
    },
    (error) => Promise.reject(error)
  )
  return http
}
