import axios from 'axios'
import jwtDecode from 'jwt-decode'
import { fromUnixTime, isFuture } from 'date-fns'
import { BASE_URL } from 'config/constants'
import { API_ROUTES } from 'config/routes'
import { PreRegistrationParams } from 'types'

export type AuthJwtPayload = {
  exp?: number
  email: string
  name: string
}

export const parseAuthJwt = async (jwtToken: string): Promise<AuthJwtPayload> => {
  try {
    const decoded: Record<string, never> = jwtDecode(jwtToken)

    return {
      exp: decoded.exp,
      email: decoded.email,
      name: decoded.displayName,
    }
  } catch (error) {
    throw new Error('Unable to parse JWT')
  }
}

export const isValidJwt = async (jwtToken: string): Promise<boolean> => {
  try {
    const { exp } = await parseAuthJwt(jwtToken)

    if (!exp || isFuture(fromUnixTime(exp))) {
      return true
    }

    return false
  } catch (error) {
    return false
  }
}

export const getJwt = async ({ customerId, productId }: PreRegistrationParams) => {
  const { data } = await axios.post(API_ROUTES.temporaryToken, { customerId, productId }, { baseURL: BASE_URL })
  return data
}

export default {
  isValidJwt,
  parseAuthJwt,
  getJwt,
}
