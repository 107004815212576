import { Flex } from '@chakra-ui/react'
import { Footer, Navbar } from 'common/components'

import 'styles/header-footer.less'

type LayoutPros = React.PropsWithChildren<unknown>

function Layout({ children }: LayoutPros) {
  return (
    <Flex direction="column" minH="100vh" justify="space-between">
      <Navbar />
      {children}
      <Footer />
    </Flex>
  )
}

export default Layout
