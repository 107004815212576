import { useQuery } from '@tanstack/react-query'
import { useHeaderFooter } from 'common/hooks'

function Footer() {
  const getContentFunction = useHeaderFooter('footer')
  const { data: footer } = useQuery(['footer'], getContentFunction, { suspense: true })

  return (
    <div
      // eslint-disable-next-line react/no-danger
      dangerouslySetInnerHTML={{ __html: footer || '' }}
      data-testid="footer-testid"
      data-scope="splunk-bootstrap"
    />
  )
}

export default Footer
