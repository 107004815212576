import { AuthState } from './types'

export const setCurrentUser = 'setCurrentUser'
export const clearCurrentUser = 'clearCurrentUser'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const reducer = (state: AuthState, action: { type: string; payload?: any }) => {
  switch (action.type) {
    case setCurrentUser: {
      return {
        currentUser: {
          name: action.payload.name,
          email: action.payload.email,
          token: action.payload.token,
        },
        isReady: true,
      }
    }
    case clearCurrentUser: {
      return {
        currentUser: undefined,
        isReady: true,
      }
    }

    default:
      return state
  }
}

export default reducer
