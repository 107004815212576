import type { ComponentStyleConfig } from '@chakra-ui/theme'

const disabledStyleProps = {
  opacity: 1.0,
  border: '1px',
  borderColor: 'brand.border.100',
  bg: 'white',
  color: 'brand.text.50',
  pointerEvents: 'auto',
  cursor: 'not-allowed',
}

const Button: ComponentStyleConfig = {
  baseStyle: {
    borderRadius: '3xl',
    color: 'white',
    fontSize: '15px',
    fontWeight: 'bold',
    height: '42px',
    letterSpacing: '0.2px',
    lineHeight: 'none',
    px: '22px',
    py: '12px',
    textAlign: 'center',
    _active: { bg: 'brand.100', borderColor: 'brand.100' },
  },
  variants: {
    solid: {
      bg: 'brand.50',
      _hover: {
        bg: 'brand.100',
        borderColor: 'brand.100',
        _disabled: {
          borderColor: 'brand.border.100',
        },
      },
      _focus: { border: '2px', borderColor: 'brand.border.50' },
      _disabled: { ...disabledStyleProps },
    },
    outline: {
      bg: 'none',
      borderColor: 'brand.50',
      color: 'brand.50',
      _hover: {
        bg: 'brand.100',
        borderColor: 'brand.100',
        color: 'white',
        _disabled: {
          borderColor: 'brand.border.100',
        },
      },
      _focus: { bg: 'brand.100', border: '2px', borderColor: 'brand.border.50', color: 'white' },
      _disabled: { ...disabledStyleProps },
    },
  },
}

export default Button
