import { ReactNode, useContext, useEffect } from 'react'
import { BASE_URL } from 'config/constants'
import { API_ROUTES } from 'config/routes'
import { parseAuthJwt, isValidJwt } from 'common/services/jwt.service'
import { parse, stringifyUrl, stringify } from 'query-string'
import { useLocation, useNavigate } from 'react-router-dom'
import { AuthContext } from './authentication.context'
import { LoadingPage } from '../LoadingPage'

type AuthenticationProps = {
  children?: ReactNode
}

function Authentication({ children }: AuthenticationProps) {
  const { authState, authActions } = useContext(AuthContext)
  const { isReady, currentUser } = authState
  const location = useLocation()
  const navigate = useNavigate()
  const queryParams = parse(location.search)

  useEffect(() => {
    const login = async () => {
      const { jwt: jwtToken, ...locationSearchParams } = queryParams

      if (jwtToken) {
        const isValid = await isValidJwt(jwtToken.toString())
        const { email, name } = await parseAuthJwt(jwtToken.toString())

        // removes the jwt token from the nav bar but does not redraw
        const search = stringify({ ...locationSearchParams })
        navigate({ search })

        if (isValid) {
          authActions.setCurrentUser({ name, email, token: jwtToken.toString() })
          return
        }
      }

      if (!currentUser) {
        authActions.clearCurrentUser()
      }
    }

    login()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (authState.isReady && !authState.currentUser) {
      const { email } = queryParams
      const returnUrl = stringifyUrl({ url: location.pathname, query: queryParams })
      const url = stringifyUrl({
        url: `${BASE_URL}${API_ROUTES.login}`,
        query: { returnUrl, ...(email && { email }) },
      })
      window.location.assign(url)
    }
  }, [authState, location.pathname, queryParams])

  if (!isReady) {
    return <LoadingPage message="Authenticating..." />
  }

  if (isReady && !currentUser) {
    return <LoadingPage message="Redirecting..." />
  }
  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>
}

export default Authentication
