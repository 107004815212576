import { createContext, ReactNode, useMemo, useReducer } from 'react'
import authReducer, { setCurrentUser, clearCurrentUser } from './reducer'
import { AuthState, ContextType, SetCurrentUserPayload } from './types'

const initialState: AuthState = { isReady: false, currentUser: undefined }
const AuthContext = createContext({} as ContextType)

function AuthProvider({ children }: { children: ReactNode }) {
  const [authState, dispatch] = useReducer(authReducer, initialState)

  const authActions = useMemo(
    () => ({
      setCurrentUser: (payload: SetCurrentUserPayload) => {
        if (payload) {
          dispatch({ type: setCurrentUser, payload })
        }
      },
      clearCurrentUser: () => dispatch({ type: clearCurrentUser }),
    }),
    [dispatch]
  )

  const value = useMemo(() => ({ authState, authActions }), [authState, authActions])

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}

export { AuthProvider, AuthContext }
