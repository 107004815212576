import { useCallback } from 'react'
import useHttp from './useHttp'

export default function useHeaderFooter(type: 'header' | 'footer' = 'header') {
  const http = useHttp()

  return useCallback(async () => {
    const { data } = await http.get<string>(type)

    return data
  }, [type, http])
}
