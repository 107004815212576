import { useContext, useEffect, useState } from 'react'
import { Box } from '@chakra-ui/react'
import { useQuery } from '@tanstack/react-query'
import { useHeaderFooter } from 'common/hooks'
import { AuthContext } from '../Authentication'

const useNavbar = () => {
  const [display, setDisplay] = useState<string>('none')
  const getContentFunction = useHeaderFooter('header')
  const { data } = useQuery(['header'], getContentFunction, { suspense: true })
  const { authState } = useContext(AuthContext)
  const currentUserName = authState?.currentUser?.name

  useEffect(() => {
    if (data) {
      document.body.classList.add('locale_en_us')

      const script = document.createElement('script')
      script.src = `https://www.splunk.com/etc.clientlibs/splunk/core/clientlibs/clientlib-navbar_bs3.js`
      script.async = true

      document.body.appendChild(script)

      return () => {
        document.body.classList.remove('locale_en_us')
        document.body.removeChild(script)
      }
    }
  }, [data])

  useEffect(() => {
    if (data && currentUserName) {
      setTimeout(() => {
        const firstLetter = currentUserName[0].toUpperCase()
        jQuery('.splunk-icon.icon-expanded-user-icon').remove()
        jQuery('.splunk-user-avatar').removeClass('hidden')
        jQuery('.splunk-user-avatar .loginUserNameIcon').text(firstLetter)
        jQuery('.loggedin-user-link.hidden').removeClass('hidden').show()
        jQuery('#account li:not(.loggedin-user-link)').addClass('hidden')
        setDisplay('block')
      }, 500)
    } else if (data) setDisplay('block')
  }, [data, currentUserName])

  return [data, display]
}

function Navbar() {
  const [navbarData, display] = useNavbar()

  return (
    <Box as="header" data-testid="header" height={{ base: '0px', md: '79px' }}>
      <Box
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{ __html: navbarData || '' }}
        data-testid="navbar-testid"
        data-scope="splunk-bootstrap"
        display={display}
      />
    </Box>
  )
}

export default Navbar
