import { BrowserRouter as Router, useRoutes } from 'react-router-dom'
import { ErrorBoundary } from 'react-error-boundary'
import ErrorPage from 'pages/error/ErrorPage'
import { Suspense } from 'react'
import { AuthProvider, LoadingPage } from 'common/components'
import ROUTES from './routes'

const AppRoutes = () => useRoutes(ROUTES)

function App() {
  return (
    <ErrorBoundary fallback={<ErrorPage />}>
      <AuthProvider>
        <Suspense fallback={<LoadingPage />}>
          <Router>
            <AppRoutes />
          </Router>
        </Suspense>
      </AuthProvider>
    </ErrorBoundary>
  )
}

export default App
